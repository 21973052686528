import "./App.css";
import Contact from "./Components/Pages/Contact";
import Login from "./Components/Pages/Login";
import PersistLogin from "./Components/Utilis/PersistLogin";
import AdminDasboard from "./Components/Admin/AdminDasboard";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "./Components/Pages/NotFoundPage";
import UnautorizedPage from "./Components/Pages/UnautorizedPage";
import RequireAuth from "./Components/Utilis/RequireAuth";
import Layout from "./Components/Utilis/Layout";
import Home from "./Components/Pages/Home";
document.title = 'Gderot';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes  */}
        <Route path="login" element={<Login />} />
        <Route path="unautorized" element={<UnautorizedPage />} />

        {/* Start - Private routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={["User", "Admin", "SuperAdmin"]} />}>
            <Route path="/" element={<Home />} />
            <Route path="contact" element={<Contact />} />
          </Route>
          {/* admin routes */}
          <Route path="admin/*"  element={<AdminDasboard />} />
        </Route>
        {/* End - Private routes */}

        {/* catch all */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
