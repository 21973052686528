import React from "react";
import { useNavigate } from "react-router-dom";

const UnautorizedPage = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div>
      <h1 className="UnautorizedPage">אין גישה לעמוד הנוכחי</h1>
      <div className="goBack">
        <button onClick={goBack}>חזור לאחור</button>
      </div>
    </div>
  );
};

export default UnautorizedPage;
