import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import LoadingAnimation from "../Common/LoadingAnimation";
import UnitFormMapComponent from "./UnitFormMapComponent";

const UnitFormComponent = ({ onClose, onSave, unitId, cityId }) => {
  const axiosPrivate = useAxiosPrivate();
  const [unit, setUnit] = useState({
    name: "",
    sourcePhone: "",
    cityId: 0,
    latitude: 0,
    longitude: 0,
    //creationDate: new Date(),
    //lastUpdatedDate: null,
    //creationUserId: 0,
    //lastReportedUserId: null,
  });
  const [unitsLocations, setUnitsLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(null);
  const [titleName, setTitleName] = useState(null);

  const getUnitDetails = async () => {
    const response = await axiosPrivate.get(`api/unit/GetById/${unitId}`);
    setUnit(response.data);
    setTitleName(response.data.name);
  };

  const getLocationsUnits = async () => {
    const response = await axiosPrivate.get(`api/unit/GetUnitsLocationsByCityId?cityId=${cityId}`);
    const mappedUnits = response.data.map((unit) => {
      return {
        id: unit.id,
        lat: unit.latitude,
        lng: unit.longitude,
        name: unit.name,
      };
    });
    console.log("mappedUnits");
    console.log(mappedUnits);
    setUnitsLocations(mappedUnits);
  };

  useEffect(() => {
    // Edit - when unitId is NOT null
    if (unitId) {
      Promise.all([getUnitDetails(), getLocationsUnits()]).then(() => setIsLoading(false));
    }
    // Creation - when unitId is null
    else {
      Promise.all([getLocationsUnits()]).then(() => setIsLoading(false));
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleMapClick = (lat, lng) => {
    unit.latitude = lat;
    unit.longitude = lng;
    setUnit((prevUnit) => ({
      ...prevUnit,
      // toFixed(9) to make the position to have no mpre than 9 decimal number after point
      latitude: lat,
      longitude: lng,
    }));
    console.log("lat: " + lat + ", lng: " + lng);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnit((prevUnit) => ({ ...prevUnit, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    let newErrors = {};

    if (!unit.name) newErrors.name = "הזן את שם היחידה";
    if (unit.sourcePhone && !/^05\d{8}$/.test(unit.sourcePhone)) newErrors.sourcePhone = "הזן מספר טלפון חוקי של 10 ספרות שמתחיל ב '05'";
    if (!unit.sourcePhone) newErrors.sourcePhone = "הזן את מספר הטלפון של היחידה";
    if (!unit.latitude || !unit.longitude || unit.latitude == 0 || unit.longitude == 0)
      newErrors.position = "הזן קו רוחב ואורך";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (unit.id) {
      axiosPrivate
        .put(`api/unit/Update/${unit.id}`, unit)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setServerError("פעולת העדכון נכשלה: מספר הטלפון כבר קיים ביחידה אחרת");
          } else {
            setServerError("התרחשה שגיאה בתהליך העדכון");
          }
        });
    } else {
      axiosPrivate
        .post(`api/unit/Add?cityId=${cityId}`, unit)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setServerError("פעולת ההוספה נכשלה: מספר הטלפון כבר קיים ביחידה אחרת");
          } else {
            setServerError("התרחשה שגיאה בתהליך ההוספה");
          }
        });
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-background">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="modal-content edit-unit-form">
          <form onSubmit={handleSubmit}>
            <h2>{unitId ? `עריכת ${titleName}` : "הוספת יחידה"}</h2>
            <div className="form-group">
              <label htmlFor="name">
                <span className="required">*</span> שם:
              </label>
              <div className="app-text-box">
                <input
                  type="text"
                  id="name"
                  name="name"
                  autoComplete="off"
                  value={unit.name}
                  className={errors.name ? "error" : ""}
                  onChange={handleInputChange}
                />
              </div>
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="sourcePhone">
                <span className="required">*</span> טלפון מקור:
              </label>
              <div className="app-text-box">
                <input
                  type="text"
                  id="sourcePhone"
                  name="sourcePhone"
                  autoComplete="off"
                  className={errors.sourcePhone ? "error" : ""}
                  value={unit.sourcePhone}
                  onChange={handleInputChange}
                />
                {errors.sourcePhone && <p className="error-message">{errors.sourcePhone}</p>}
              </div>
            </div>
            <div className="form-group-split">
              <div className="form-group">
                <label htmlFor="latitude">
                  <span className="required">*</span> קו רוחב:
                </label>
                <div className="app-text-box">
                  <input
                    type="number"
                    id="latitude"
                    name="latitude"
                    step="0.0001"
                    autoComplete="off"
                    className={errors.position ? "error" : ""}
                    value={unit.latitude}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="longitude">
                  <span className="required">*</span> קו אורך:
                </label>
                <div className="app-text-box">
                  <input
                    type="number"
                    id="longitude"
                    name="longitude"
                    step="0.0001"
                    autoComplete="off"
                    className={errors.position ? "error" : ""}
                    value={unit.longitude}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            {errors.position && <p className="error-message error-message-unit-location">{errors.position}</p>}

            <UnitFormMapComponent onMapClick={handleMapClick} unitId={unitId} units={unitsLocations} unitState={unit} />
            {serverError && <p className="error-message">{serverError}</p>}
            <div className="form-actions">
              <button type="submit">שמור</button>
              <button className="cancel" type="button" onClick={onClose}>
                ביטול
              </button>
            </div>
          </form>
        </div>
      )}
    </div>,
    document.getElementById("portal")
  );
};

export default UnitFormComponent;
