import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import LoadingAnimation from "../Common/LoadingAnimation";

const CityFormComponent = ({ onClose, onSave, cityId }) => {
  const axiosPrivate = useAxiosPrivate();
  const [city, setCity] = useState({ name: "", code: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(null);
  const [titleName, setTitleName] = useState(null);

  useEffect(() => {
    // if that is a edit mode get the data first, else just remove the loading
    if (cityId) {
      setIsLoading(true);
      axiosPrivate.get(`api/city/GetById/${cityId}`).then((response) => {
        setCity(response.data);
        setTitleName(response.data.name);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
    // Add event listeners for keydown events
    window.addEventListener("keydown", handleKeyDown);
    // Remove event listeners when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCity((prevCity) => ({ ...prevCity, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    let newErrors = {};

    if (!city.name) newErrors.name = "הזן את שם העיר";
    if (!city.code) newErrors.code = "הזן את קוד העיר";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (city.id) {
      axiosPrivate
        .put(`api/city/Update/${city.id}`, city)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setServerError("פעולת העדכון נכשלה: קוד העיר שהוזן כבר קיים בעיר אחרת");
          } else {
            setServerError("התרחשה שגיאה בתהליך העדכון");
          }
        });
    } else {
      axiosPrivate
        .post("api/city/Add", city)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setServerError("פעולת ההוספה נכשלה: קוד העיר שהוזן כבר קיים בעיר אחרת");
          } else {
            setServerError("התרחשה שגיאה בתהליך ההוספה");
          }
        });
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-background">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <h2>{cityId ? `עריכת ${titleName}` : "הוספת עיר"}</h2>
            <div className="form-group">
              <label htmlFor="name">
                <span className="required">*</span> שם:
              </label>
              <div className="app-text-box">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={city.name}
                  className={errors.name ? "error" : ""}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="code">
                <span className="required">*</span> קוד:
              </label>
              <div className="app-text-box">
                <input
                  type="text"
                  id="code"
                  name="code"
                  className={errors.code ? "error" : ""}
                  value={city.code}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                {errors.code && <p className="error-message">{errors.code}</p>}
              </div>
            </div>
            {serverError && <p className="error-message">{serverError}</p>}
            <div className="form-actions">
              <button type="submit">שמור</button>
              <button className="cancel" type="button" onClick={onClose}>
                ביטול
              </button>
            </div>
          </form>
        </div>
      )}
    </div>,
    document.getElementById("portal")
  );
};

export default CityFormComponent;
