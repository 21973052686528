import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import DataTable from "../Common/DataTable";
import LoadingAnimation from "../Common/LoadingAnimation";
import { Link } from "react-router-dom";
import AutocompleteTextBox from "../Common/AutocompleteTextBox";
import CityFormComponent from "./CityFormComponent";

const PAGE_SIZE = 25;
const TITLE = "עריכת ערים";

const ManageCities = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0); // the current page inporant for thae dataTable component to get changing in paging
  const [pageCount, setPageCount] = useState(0); // total pages for paging

  // for popup model
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingCityId, setEditingCityId] = useState(null);

  const allCitiesNameRef = useRef("");
  // The currently selected search options states to save it before clicking on search to prevent API call use before that.
  const [searchText, setSearchText] = useState("");
  // The data set after clicking the search button prevents API usage of the selections before the search click.
  const searchTextRef = useRef("");

 
  useEffect(() => {
    setIsLoading(true);
    Promise.all([getDataList(0), getCitiesName()]).then(() => setIsLoading(false));
  }, []);

  const getDataList = async (pageNumber) => {
    console.log("searchText = " + searchTextRef.current);
    // set the pageIndex - that is imporant for the DataTable component for currect paging
    setPageIndex(pageNumber);
    const response = await axiosPrivate.get(
      `api/City/GetPaged?pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}&searchText=${searchTextRef.current}`
    );
    setData(response.data.cities);
    setPageCount(response.data.totalPages);
    //console.log(response.data.totalPages);
  };

  const getCitiesName = async () => {
    const response = await axiosPrivate.get("/api/City/GetNames");
    const citiesName = response.data.map((city) => ({
      label: city.name,
    }));
    allCitiesNameRef.current = citiesName;
  };

   // popup functions
   const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleEditClick = (id) => {
    setEditingCityId(id);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingCityId(null);
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleSave = () => {
    handleCloseModal();
    getDataList(pageIndex);
  };

  const handlePageChange = (value) => {
    // make sure pageIndex is not negative
    if (value < 0) value = 0;
    getDataList(value);
  };

  const handleSerachTextChange = (value) => {
    setSearchText(value);
    console.log(value);
  };

  const handleSearch = () => {
    searchTextRef.current = searchText;
    getDataList(0);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "שם עיר",
        accessor: "name",
      },
      {
        Header: "קוד עיר",
        accessor: "code",
      },
      {
        Header: "הצגת דוחות",
        accessor: "Reports",
        Cell: ({ row }) => (
            <Link to={`/admin/Reports/${row.original.id}`} className="manage-units-link">מעבר לדוחות</Link>
          ),
      },
      {
        Header: "ניהול יחידות",
        accessor: "Units",
        Cell: ({ row }) => (
            <Link to={`/admin/ManageUnits/${row.original.id}`} className="manage-units-link">מעבר לניהול יחידות</Link>
          ),
      },
      {
        Header: "עריכה",
        accessor: "editId",
        Cell: ({ row }) => (
          <button type="button" className="edit-btn" onClick={() => handleEditClick(row.original.id)} />
        ),
      },
    ],
    []
  );

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <>
      {isAddModalOpen && <CityFormComponent onClose={handleCloseModal} onSave={handleSave} />}
      {isEditModalOpen && <CityFormComponent onClose={handleCloseModal} onSave={handleSave} cityId={editingCityId} />}
      <div className="admin-manage-page">
        <h1>{TITLE}</h1>
        <div className="admin-manage-page-top">
          <div className="search-table-container">
            <div className="search-table-textbox">
              {allCitiesNameRef.current && (
                <AutocompleteTextBox
                  className="basic-single"
                  isRtl={true}
                  // options={allUserNamesRef.current}
                  options={allCitiesNameRef.current}
                  onChange={handleSerachTextChange}
                  placeholder="חפש עיר.."
                  name="searchtext"
                />
              )}
            </div>
            <div className="search-table-button">
              <button type="button" onClick={handleSearch}>
                חפש
              </button>
            </div>
          </div>

          <div className="add-button">
            <button type="button" onClick={handleAddClick}>
              צור חדש
            </button>
          </div>
        </div>
        {data && data.length > 0 ? (
          <DataTable
            columns={columns}
            data={data}
            onPageChange={handlePageChange}
            pageIndex={pageIndex}
            isLoading={isLoading}
            pageCount={pageCount}
            pageSize={PAGE_SIZE}
          />
        ) : (
          <div>לא נמצאו נתונים להצגה</div>
        )}
      </div>
    </>
  );
};

export default ManageCities;
