import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import ColorMapMarker from "../HomeMap/ColorMapMarker";
import { GoogleMapKey } from "../../constants";

const UnitFormMapComponent = ({ onMapClick, unitId, units, unitState }) => {
  const mapRef = useRef();
  const mapApiRef = useRef();
  const canterRef = useRef({ lat: 31.5, lng: 34.75 });
  const markerRef = useRef(null);

  // center the map and set the correct zoom over the all units
  const SetMapBonds = () => {
    if (units && units.length > 0) {
      if (mapRef.current) {
        const bounds = new window.google.maps.LatLngBounds();
        units.forEach((unit) => {
          bounds.extend(new window.google.maps.LatLng(unit.lat, unit.lng));
        });
        mapRef.current.fitBounds(bounds);
      }
    }
  };

  // change position when long and lat form textbox is changing
  useEffect(() => {
    if (mapRef.current && unitState.latitude != 0 && unitState.longitude != 0) {
      var formLat = Number(unitState.latitude);
      var formLng = Number(unitState.longitude);

      // in case marker already exists check if there is change
      if (markerRef.current) {
        var markerPos = markerRef.current.position;
        console.log("formLat: " + formLat);
        console.log("formLng: " + formLng);

        console.log("markerPos.lat(): " + markerPos.lat());
        console.log("markerPos.lng(): " + markerPos.lng());

        if (markerPos.lat() != formLat || markerPos.lng() != formLng) {
          setPointerMaker(formLat, formLng);
          mapRef.current.setCenter({ lat: formLat, lng: formLng });
        }
      }
      // in case the user type the location and not yet set the marker
      else {
        setPointerMaker(formLat, formLng);
        mapRef.current.setCenter({ lat: formLat, lng: formLng });
      }
    }
  }, [unitState.latitude, unitState.longitude]);

  // create the red pointer marker over this edting unit
  const SetCurrentLocation = async () => {
    if (unitId != null) {
      const unit = units.find((unit) => unit.id == unitId);
      if (unit) {
        await setPointerMaker(unit.lat, unit.lng);
        mapRef.current.setCenter({ lat: unit.lat, lng: unit.lng });
      }
    }

    // fix the zoom because if there is only one unit in the map the zoom will be the too much zoom-in
    const currentZoom = mapRef.current.getZoom();
    if (units && units.length == 1 && currentZoom > 15) {
      mapRef.current.setZoom(15);
    }
  };

  // set the red marker of the set location
  const setPointerMaker = async (lat, lng) => {
    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    } else {
      const marker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapRef.current,
        draggable: false,
      });
      markerRef.current = marker;
    }
  };

  // on map click put the marker in the clicked location and implement onMapClick that change the form lat and lng
  const handleMapClick = async ({ lat, lng }) => {
    var fixedLat = Number(lat.toFixed(9));
    var fixedLng = Number(lng.toFixed(9));
    await setPointerMaker(fixedLat, fixedLng);
    await onMapClick(fixedLat, fixedLng);
  };

  return (
    <div className="unit-edit-map">
      <div className="zoom_out_map" onClick={SetMapBonds} title="מרכוז יחידות"></div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GoogleMapKey,
          language: "he",
        }}
        yesIWantToUseGoogleMapApiInternals
        center={canterRef.current}
        defaultZoom={8}
        onGoogleApiLoaded={({ map, mapApi }) => {
          mapRef.current = map;
          mapApiRef.current = mapApi;
          SetMapBonds();
          SetCurrentLocation();
        }}
        options={{
          fullscreenControl: false,
        }}
        onClick={handleMapClick}
      >
        {units &&
          units.map(
            (unit) =>
              // don't take the unit.id !== UnitId because it's have to be the marker
              (unitId == null || unit.id != unitId) && (
                <ColorMapMarker
                  key={unit.name}
                  lat={unit.lat}
                  lng={unit.lng}
                  text={unit.name}
                  isOpen={false}
                  isFlashing={false}
                  sourcePhone={unit.sourcePhone}
                  boxSize={58}
                  fontSize={12}
                  borderSize={3}
                  padding={4}
                />
              )
          )}
      </GoogleMapReact>
    </div>
  );
};

export default UnitFormMapComponent;
