import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../Hooks/useRefreshToken";
import useAuth from "../../Hooks/useAuth";
import LoadingAnimation from "../Common/LoadingAnimation";
import Navigation from "../Navigation/NavigationComponent";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  //console.log("PersistLogin start");
  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        //console.log("before refresh token from Persist Login");
        await refresh();
      } catch (err) {
        //console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    //console.log("accessToken: " + auth?.accessToken);
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    //console.log(`isLoading: ${isLoading}`);
    //console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {
            auth?.userName ? <Navigation /> : null
          }
          <Outlet />
        </>
      )}
    </>
  );
};

export default PersistLogin;
