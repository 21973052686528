import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useAuth from "../../Hooks/useAuth";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import LoadingAnimation from "../Common/LoadingAnimation";
import Select from "react-select";
import { Role } from "../../constants";

const UserFormComponent = ({ onClose, onSave, userId, citiesRef, rolesRef }) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [user, setUser] = useState({
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    cityId: 0,
    roleId: 0,
    isActive: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(null);
  const [titleName, setTitleName] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const isSuperAdmin = auth?.roles?.includes("SuperAdmin");

  const getUserDetails = async () => {
    const response = await axiosPrivate.get(`api/user/GetById/${userId}`);
    setUser(response.data);
    setTitleName(response.data.userName);
  };

  useEffect(() => {
    // Edit - when userId is NOT null
    if (userId) {
      Promise.all([getUserDetails()]).then(() => setIsLoading(false));
    }
    // Creation - when userId is null
    else {
      setIsLoading(false);
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: !!checked }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    var selectedValue = 0;
    if (selectedOption) selectedValue = selectedOption.value;
    setUser((prevUser) => ({ ...prevUser, [name]: selectedValue }));
  };

  const handleToggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    let newErrors = {};

    if (!user.firstName) newErrors.firstName = "הזן שם פרטי";
    if (!user.lastName) newErrors.lastName = "הזן שם משפחה";
    if (!/\S+@\S+\.\S+/.test(user.email)) {
      newErrors.email = "הזן כתובת מייל תקינה";
    }
    if (!user.email) newErrors.email = "הזן כתובת מייל";
    if (!/^[a-zA-Z]+[\w-]*$/.test(user.userName)) {
      newErrors.userName = "הזן רק אותיות באנגלית, מספרים, קו תחתון או מקף";
    }
    if (!/^[a-zA-Z]/.test(user.userName)) {
      newErrors.userName = "שם המשתמש חייב להתחיל באות באנגלית";
    }
    if (!user.userName) newErrors.userName = "הזן שם משתמש";
    if (isSuperAdmin && !user.roleId) newErrors.roleId = "בחר תפקיד";
    // make superAdmin able to create empty cityId
    if (isSuperAdmin && user.roleId != Role.SuperAdmin && !user.cityId) newErrors.cityId = "בחר עיר";
    if (isSuperAdmin && user.roleId == Role.SuperAdmin && user.cityId) newErrors.cityId = "משתמש מסוג סופר אדמין לא יכול להיות משיוך לעיר";
    if (!userId && /[\u0590-\u05FF]/.test(user.password)) newErrors.password = "הסיסמא לא יכולה להכיל תווים בעברית";
    if (!userId && !user.password) newErrors.password = "הזן סיסמא";
    if (!user.phoneNumber) newErrors.phoneNumber = "הזן מספר טלפון";

    if (Object.keys(newErrors).length > 0) {
      console.log(newErrors);
      setErrors(newErrors);
      return;
    }

    if (user.id) {
      axiosPrivate
        .put(`api/user/Update/${user.id}`, user)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setServerError("פעולת העדכון נכשלה: שם המשתמש שהוזן כבר קיים בחשבון אחר");
          } else {
            setServerError("התרחשה שגיאה בתהליך ההוספה");
          }
        });
    } else {
      axiosPrivate
        .post(`api/user/Add`, user)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setServerError("פעולת ההוספה נכשלה: שם המשתמש שהוזן כבר קיים בחשבון אחר");
          } else {
            setServerError("התרחשה שגיאה בתהליך ההוספה");
          }
        });
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-background">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="modal-content edit-user-form">
          <form onSubmit={handleSubmit} autocomplete="off">
            <h2>{userId ? `עריכת ${titleName}` : "הוספת משתמש"}</h2>
            <div className="form-group">
              <label htmlFor="userName">
                <span className="required">*</span> שם משתמש:
              </label>
              <div className="app-text-box">
                <input
                  type="text"
                  id="userName"
                  dir="ltr"
                  name="userName"
                  autoComplete="off"
                  value={user.userName}
                  className={errors.userName ? "error" : ""}
                  onChange={handleInputChange}
                />
              </div>
              {errors.userName && <p className="error-message">{errors.userName}</p>}
            </div>

            {!userId ? (
              <div className="form-group form-group-password">
                <label htmlFor="userName">
                  <span className="required">*</span> סיסמא:
                </label>
                <div className="app-text-box">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    dir="ltr"
                    name="password"
                    autocomplete="new-password"
                    value={user.password}
                    className={errors.password ? "error" : ""}
                    onChange={handleInputChange}
                  />
                  <div
                    className={`toggle-password ${showPassword ? "toggle-password-active" : ""}`}
                    onClick={handleToggleShowPassword}
                  />
                </div>
                {errors.password && <p className="error-message">{errors.password}</p>}
              </div>
            ) : (
              <></>
            )}

            <div className="form-group-split">
              <div className="form-group">
                <label htmlFor="firstName">
                  <span className="required">*</span> שם פרטי:
                </label>
                <div className="app-text-box">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    autoComplete="off"
                    value={user.firstName}
                    className={errors.firstName ? "error" : ""}
                    onChange={handleInputChange}
                  />
                </div>
                {errors.firstName && <p className="error-message">{errors.firstName}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="lastName">
                  <span className="required">*</span> שם משפחה:
                </label>
                <div className="app-text-box">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    autoComplete="off"
                    value={user.lastName}
                    className={errors.lastName ? "error" : ""}
                    onChange={handleInputChange}
                  />
                </div>
                {errors.lastName && <p className="error-message">{errors.lastName}</p>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">
                <span className="required">*</span> אימייל:
              </label>
              <div className="app-text-box">
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  value={user.email}
                  className={errors.email ? "error" : ""}
                  onChange={handleInputChange}
                />
              </div>
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">
                <span className="required">*</span> מספר טלפון:
              </label>
              <div className="app-text-box">
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  autoComplete="off"
                  value={user.phoneNumber}
                  className={errors.phoneNumber ? "error" : ""}
                  onChange={handleInputChange}
                />
              </div>
              {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>}
            </div>
            {isSuperAdmin ? (
              <>
                <div className="form-group">
                  <label htmlFor="cityId">
                    <span className="required">*</span> עיר:
                  </label>
                  <div className="app-select-box">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={handleSelectChange}
                      defaultValue={citiesRef.current.find((option) => option.value === user.cityId)}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={true}
                      isSearchable={true}
                      maxMenuHeight={150}
                      name="cityId"
                      options={citiesRef.current}
                      placeholder="בחר עיר"
                      noOptionsMessage={() => "לא נמצאו תוצאות"}
                    />
                  </div>
                  {errors.cityId && <p className="error-message">{errors.cityId}</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="roleId">
                    <span className="required">*</span> תפקיד:
                  </label>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleSelectChange}
                    defaultValue={rolesRef.current.find((option) => option.value === user.roleId)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={true}
                    isSearchable={false}
                    maxMenuHeight={150}
                    name="roleId"
                    options={rolesRef.current}
                    placeholder="בחר תפקיד"
                    noOptionsMessage={() => "לא נמצאו תוצאות"}
                  />
                  {errors.roleId && <p className="error-message">{errors.roleId}</p>}
                </div>
              </>
            ) : (
              <>
                <input type="hidden" name="roleId" value={user.roleId} />
                <input type="hidden" name="cityId" value={user.cityId} />
              </>
            )}
            <div className="form-group form-group-checkbox">
              <label htmlFor="isActive">פעיל:</label>
              <input
                type="checkbox"
                id="isActive"
                name="isActive"
                checked={user.isActive}
                onChange={handleCheckboxChange}
              />
            </div>
            {serverError && <p className="error-message">{serverError}</p>}
            <div className="form-actions">
              <button type="submit">שמור</button>
              <button className="cancel" type="button" onClick={onClose}>
                ביטול
              </button>
            </div>
          </form>
        </div>
      )}
    </div>,
    document.getElementById("portal")
  );
};

export default UserFormComponent;
