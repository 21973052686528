import axios from 'axios';
import { BASE_URL } from '../constants';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    Headers: {'Content-Type' : 'application/json'},
    withCredntial: true
});