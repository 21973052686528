import React from "react";
import { Route, Routes } from "react-router-dom";
import ManageUsers from "./ManageUsers";
import ManageCities from "./ManageCities";
import RequireAuth from "../Utilis/RequireAuth";
import ManageUnits from "./ManageUnits";
import ReportsComponent from "./ReportsComponent";


const AdminDasboard = () => {
  return (
    <div className="admin-content">
      <Routes>
        <Route element={<RequireAuth allowedRoles={["Admin", "SuperAdmin"]} />}>
          <Route path="manageUsers" element={<ManageUsers />} />
          <Route path="manageUnits/:cityId?" element={<ManageUnits />} />
          <Route path="reports/:cityId?" element={<ReportsComponent />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
          <Route path="manageCities" element={<ManageCities />} />
        </Route>
      </Routes>
      {/* <div className="flexGrow">
        <Link to="/">Home</Link>
      </div> */}
    </div>
  );
};

export default AdminDasboard;
