import React from "react";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  
  return (
    <div>
      <h1>העמוד לא נמצא</h1>
      <div className="goBack">
        <button onClick={goBack}>חזור לאחור</button>
      </div>
    </div>
  );
}

export default NotFoundPage;
