import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import NotificationsTable from "./NotificationsTable";
import ColorMapMarker from "./ColorMapMarker";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useNotificationConnection from "../../Hooks/useNotificationConnection";
import Select from "react-select";
import notificationSound from "../../Audio/notification.mp3";
import LoadingAnimation from "../Common/LoadingAnimation";
import { GoogleMapKey } from "../../constants";
import useAuth from "../../Hooks/useAuth";

const HomeMap = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [units, setUnits] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [zoom, setZoom] = useState(11);
  const audio = new Audio(notificationSound);

  // map detials
  const mapRef = useRef();
  const mapApiRef = useRef();
  const cityRef = useRef();
  const openNotificationsRef = useRef(null);
  const canterRef = useRef({ lat: 0, lng: 0 });
  const unitIdToFlash = useRef(null);
  const responseData = useRef(null);
  const isSuperAdmin = auth?.roles?.includes("SuperAdmin");

  const { subscribeToNotifications } = useNotificationConnection();

  useEffect(() => {
    const initialData = async () => {
      setIsLoading(true);
      await fetchNotifications();
      //subscribeToNotifications(() => fetchNotifications());
      await subscribeToNotifications(cityRef.current, onNewNotification);
    };
    initialData();
  }, []);

  const fetchNotifications = async () => {
    try {
      // if this is super admin get all units and all notifications
      let RequestUrl = "/api/notifications/GetOpenNotifications";
      if (isSuperAdmin) RequestUrl = "/api/notifications/GetAllOpenNotifications";

      const response = await axiosPrivate.get(RequestUrl);
      responseData.current = response.data;
      await SetMapUnits(response.data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const onNewNotification = async (notification) => {
    // play sound and center map if that is new notification
    if (!notification.closedDate) {
      unitIdToFlash.current = notification.unitId;
      console.log("notification open");
      console.log(notification);
      audio.play();
      await CenterMapOneUnit(notification.unitId);
      await fetchNotifications();
      console.log("notification.unitId");
      console.log(notification.unitId);
    }
    // loading data because that is closed notication
    else {
      // remove the flashing if the closed notification have it
      if (unitIdToFlash.current == notification.unitId) {
        unitIdToFlash.current = null;
      }
      await fetchNotifications();
      if (openNotificationsRef.current && openNotificationsRef.current.length == 0) {
        SetMapBonds();
      }
    }
  };

  const closeNotification = async (notification) => {
    const confirmed = window.confirm("האם אתה בטוח?");
    if (confirmed) {
      const formData = new FormData();
      formData.append("id", notification.id);
      const response = await axiosPrivate.post("/api/notifications/Close", formData);
      console.log(response);
    }
  };

  const SetMapUnits = async (dataResult) => {
    // make super admin city set to 1 to make it get all the notifications of every city
    if (isSuperAdmin) cityRef.current = 0;
    else cityRef.current = dataResult.cities[0].id;

    // set the notifications for the notification table
    const openNotifications = dataResult.notifications.map((notification) => {
      const unit = dataResult.units.find((unit) => unit.id === notification.unitId);
      const city = dataResult.cities.find((city) => city.id === unit.cityId);
      const isOpen = notification.closedDate === null ? true : false;

      return {
        id: notification.id,
        sourcePhone: unit.sourcePhone,
        name: unit.name,
        cityName: city.name,
        cityId: city.id,
        unitId: unit.id,
        creactionDate: notification.creactionDate,
        isOpen: isOpen,
        lat: unit.latitude,
        lng: unit.longitude,
      };
    });

    openNotificationsRef.current = openNotifications;

    // set the loctions for the Units of the map
    const mappedUnits = dataResult.units.map((unit) => {
      const notifications = dataResult.notifications.filter((notification) => notification.unitId === unit.id);
      const isOpen = notifications.length > 0;

      // set the notification as flashing
      let isFlashing = false;
      isFlashing = unit.id == unitIdToFlash.current;

      return {
        id: unit.id,
        sourcePhone: unit.sourcePhone,
        lat: unit.latitude,
        lng: unit.longitude,
        name: unit.name,
        isOpen: isOpen,
        isFlashing: isFlashing,
      };
    });
    localStorage.setItem("openNotifications", openNotifications.length);
    // Dispatch a storage event to notify other tabs of the count update
    window.dispatchEvent(new StorageEvent("storage", { key: "openNotifications" }));
    //console.log("mappedUnits");
    //console.log(mappedUnits);
    setUnits(mappedUnits);
  };

  const CenterMapOneUnit = async (unitId) => {
    console.log("responseData.current");
    console.log(responseData.current);
    if (responseData.current) {
      const unit = responseData.current.units.find((unit) => unit.id == unitId);

      if (mapRef.current && unit) {
        const currentZoom = mapRef.current.getZoom();
        if (currentZoom <= 15) {
          mapRef.current.setZoom(16);
        }
        mapRef.current.setCenter({ lat: unit.latitude, lng: unit.longitude });
      }
    }
  };

  const SetMapBonds = () => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      responseData.current.units.forEach((unit) => {
        bounds.extend(new window.google.maps.LatLng(unit.latitude, unit.longitude));
      });
      mapRef.current.fitBounds(bounds);
    }
  };

  const handleComboBoxSelected = async (unit) => {
    if (unit) {
      unitIdToFlash.current = unit.id;
      await CenterMapOneUnit(unit.id);
    }
    // When user clicks 'x' in the select list to clear selection, zoom and position reset.
    else {
      unitIdToFlash.current = null;
      SetMapBonds();
    }
    await SetMapUnits(responseData.current);
  };

  const handleTableNotifactionClick = async (notification) => {
    if (notification) {
      unitIdToFlash.current = notification.unitId;
      await SetMapUnits(responseData.current);
      await CenterMapOneUnit(notification.unitId);
    }
  };

  const handleZoomChange = (event) => {
    setZoom(event);
    console.log("event");
    console.log(event);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : units ? (
    <div
      className={`home-notifications ${
        openNotificationsRef.current && openNotificationsRef.current.length > 0 ? "" : "no-open-notification"
      }`}
    >
      <div className="units-map">
        <div className="units-map-select-list">
          <Select
            className="basic-single"
            classNamePrefix="select"
            onChange={handleComboBoxSelected}
            defaultValue={null}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={true}
            isSearchable={true}
            maxMenuHeight={300}
            name="unit"
            options={units}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.sourcePhone}
            placeholder="חפש יחידה.."
            noOptionsMessage={() => "לא נמצאו תוצאות"}
          />
        </div>
        <div className="zoom_out_map" onClick={SetMapBonds} title="מרכוז יחידות"></div>

        <GoogleMapReact
          bootstrapURLKeys={{
            key: GoogleMapKey,
            language: "he",
          }} // google api key
          //defaultCenter={center}
          center={canterRef.current}
          onZoomAnimationStart={handleZoomChange}
          onZoomAnimationEnd={handleZoomChange}
          defaultZoom={11}
          boxSize={58}
          fontSize={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, mapApi }) => {
            mapRef.current = map;
            mapApiRef.current = mapApi;
            SetMapBonds();
          }}
        >
          {units &&
            units.map((unit) => (
              <ColorMapMarker
                key={unit.name}
                lat={unit.lat}
                lng={unit.lng}
                text={unit.name}
                isOpen={unit.isOpen}
                isFlashing={unit.isFlashing}
                sourcePhone={unit.sourcePhone}
                zoom={zoom}
              />
            ))}
        </GoogleMapReact>
      </div>
      {openNotificationsRef.current ? (
        <NotificationsTable
          openNotifications={openNotificationsRef.current}
          onClickUnitTable={handleTableNotifactionClick}
          OnClickClose={closeNotification}
        />
      ) : null}
    </div>
  ) : (
    <div>לא נמצאו נתונים להצגה</div>
  );
};

export default HomeMap;
