import {Outlet} from "react-router-dom"
import Navigation from "../Navigation/NavigationComponent"
import useAuth from "../../Hooks/useAuth";

import React from 'react'

const Layout = () => {
  const {auth} = useAuth();
  //console.log(auth?.roles);
  return (
    <main id="App">
      <Outlet />
    </main>
  )
}

export default Layout