const ColorMapMarker = ({ text, isOpen, isFlashing, boxSize, fontSize, borderSize, padding, zoom }) => {
  const markerClassName = [
    'home-map-marker',
    `map-marker-zoom-${zoom}`,
    zoom <= 13 ? 'map-marker-zoom-small' : '',
    isOpen ? 'home-map-marker-is-open' : '',
    isFlashing ? 'home-map-marker-is-flashing' : '',
  ].join(' ');

  const ColorMapMarkerSyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates

    width: boxSize,
    height: boxSize,
    left: -boxSize / 2,
    top: -boxSize / 2,
    borderRadius: boxSize,
    fontSize: fontSize,
    borderWidth: borderSize,
    padding: padding,
  };

  return (
    <div className={markerClassName} style={ColorMapMarkerSyle}>
      {text}
    </div>
  );
};

export default ColorMapMarker;
