import React from "react";

const NotificationsTable = ({ openNotifications, OnClickClose, onClickUnitTable }) => {
  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Asia/Jerusalem",
      useGrouping: false,
    };
    return new Date(date).toLocaleString("he-IL", options).replace(",", "");
  };
  console.log("table:");
  console.log(openNotifications);

  const handleMapLoction = (notification) => {
    onClickUnitTable(notification);
  };

  const handleCloseNotification = (notification) => {
    OnClickClose(notification);
  };

  return openNotifications && openNotifications.length > 0 ? (
    <div className="home-open-notifications-table app-table">
      <table>
        <thead>
          <tr>
            <th>שם יחידה</th>
            <th>מספר יחידה</th>
            <th>יישוב</th>
            <th>תאריך ארוע</th>
            <th>סגירת ארוע</th>
          </tr>
        </thead>
        <tbody>
          {openNotifications.map((notification) => (
            <tr key={notification.id}>
              <td className="pointer" onClick={() => handleMapLoction(notification)}>
                {notification.name}
              </td>
              <td className="pointer" onClick={() => handleMapLoction(notification)}>
                {notification.sourcePhone}
              </td>
              <td className="pointer" onClick={() => handleMapLoction(notification)}>
                {notification.cityName}
              </td>
              <td className="creaction-date pointer" onClick={() => handleMapLoction(notification)}>
                {formatDate(notification.creactionDate)}
              </td>
              <td>
                <button onClick={() => handleCloseNotification(notification)}>סיים ארוע</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default NotificationsTable;
