const Pagination = ({ pageCount, handlePageChange, canPreviousPage, canNextPage, pageIndex }) => {
  return (
    <div className="pagination-container">
      <div className="pagination-list">
        {/* move to the first page */}
        <button onClick={() => handlePageChange(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        {/* move to the previous page */}
        <button onClick={() => handlePageChange(pageIndex - 1)} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        {/* move two pages up and two pages down */}
        {[...Array(5)].map((_, i) => {
          let page;
          // when the current page is 1-3
          if (pageIndex <= 2) {
            page = i;
          } 
          // when the current page is one of the 3 last pages, so we need to count from last page (pageCount) and do it 5 time
          else if (pageIndex >= pageCount - 3) {
            page = pageCount - 5 + i;
          } 
          // when the page is not the first 3 or the last 3 show page 5 pages from current page(page index) -2
          else {
            page = pageIndex - 2 + i;
          }
          if (page >= 0 && page < pageCount) {
            return (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={pageIndex === page ? "current-page" : ""}
              >
                {page + 1}
              </button>
            );
          }
          return null;
        })}
        {/* move to the next page */}
        <button onClick={() => handlePageChange(pageIndex + 1)} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        {/* move to the last page */}
        <button onClick={() => handlePageChange(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
      </div>
      <div className="pagination-info">
        מציג דף {pageIndex + 1} מתוך {pageCount}
      </div>
    </div>
  );
};

export default Pagination;
