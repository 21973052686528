import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";
import DataTable from "../Common/DataTable";
import LoadingAnimation from "../Common/LoadingAnimation";
import Select from "react-select";
import AutocompleteTextBox from "../Common/AutocompleteTextBox";
import UserFormComponent from "./UserFormComponent";
import UserChangePasswordComponent from "./UserChangePasswordComponent";

const PAGE_SIZE = 25;
const TITLE = "עריכת משתמשים";

const ManageUsers = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0); // the current page inporant for thae dataTable component to get changing in paging
  const [pageCount, setPageCount] = useState(0); // total pages for paging

  const allCitiesNameRef = useRef("");
  const allRolesNameRef = useRef("");
  const allUserNamesRef = useRef(null);

  // popup variables
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [IsChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);

  // The currently selected search options states to save it before clicking on search to prevent API call use before that.
  const [searchText, setSearchText] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  // The data set after clicking the search button prevents API usage of the selections before the search click.
  const searchTextRef = useRef("");
  const selectedRoleRef = useRef("");
  const selectedCityRef = useRef(0);

  // popup functions
  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleEditClick = (id) => {
    setEditingUserId(id);
    setIsEditModalOpen(true);
  };

  const handleChangePasswordClick = (id) => {
    setEditingUserId(id);
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingUserId(null);
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setIsChangePasswordModalOpen(false);
  };

  const handleSave = async () => {
    handleCloseModal();
    getAllUserNames();
    getDataList(pageIndex);
  };

  //

  const handlePageChange = (value) => {
    // make sure pageIndex is not negative
    if (value < 0) value = 0;
    getDataList(value);
  };

  const handleSerachTextChange = (value) => {
    setSearchText(value);
    console.log(value);
  };

  const handleRoleChange = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption) setSelectedRole(selectedOption.value);
    else setSelectedRole(0);
  };

  const handleCityChange = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption) setSelectedCity(selectedOption.value);
    else setSelectedCity(0);
  };

  const handleSearch = () => {
    // only super admin have select for roles and cities 
    if (auth?.roles?.includes("SuperAdmin")) {
      selectedRoleRef.current = selectedRole;
      selectedCityRef.current = selectedCity;
    }
    searchTextRef.current = searchText;
    getDataList(0);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!selectedCityRef.current && auth?.roles?.includes("Admin")) {
      selectedCityRef.current = auth?.cityId;
    }
    const promises = [];
  
    if (auth?.roles?.includes("Admin")) {
      // for admin set the city to his current city to show only user from that city.
      selectedCityRef.current = auth?.cityId;
    } else if (auth?.roles?.includes("SuperAdmin")) {
      promises.push(getAllRolesName());
      promises.push(getCitiesName());
    }
    promises.push(getAllUserNames());
    promises.push(getDataList(0));

    Promise.all(promises).then(() => {
      setIsLoading(false);
    });
  }, []);

  const getDataList = async (pageNumber) => {
    console.log("searchText = " + searchTextRef.current);
    console.log("selectedRole = " + selectedRoleRef.current);
    // set the pageIndex - that is imporant for the DataTable component for currect paging
    setPageIndex(pageNumber);
    const response = await axiosPrivate.get(
      `api/user/GetPaged?pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}&searchText=${searchTextRef.current}&selectedRole=${selectedRoleRef.current}&selectedCity=${selectedCityRef.current}`
    );
    setData(response.data.users);
    setPageCount(response.data.totalPages);
    //console.log(response.data.totalPages);
  };

  const getCitiesName = async () => {
    const response = await axiosPrivate.get("/api/City/GetNames");
    const citiesName = response.data.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    allCitiesNameRef.current = citiesName;
  };

  const getAllUserNames = async () => {
    const response = await axiosPrivate.get("api/user/GetAllUserNames");
    const userNames = response.data.map((user, index) => ({
      label: user,
    }));
    console.log("userNames");
    console.log(userNames);
    allUserNamesRef.current = userNames;
  };

  const getAllRolesName = async () => {
    const response = await axiosPrivate.get("api/user/GetRolesName");
    const rolesName = response.data.map((role) => ({
      value: role.id,
      label: role.name,
    }));
    allRolesNameRef.current = rolesName;
  };

  const formatDate = (value) => {
    if (!value) return ""; // return empty string if value is null or undefined
    const date = new Date(value);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "שם משתמש",
        accessor: "userName",
      },
      {
        Header: "אימייל",
        accessor: "email",
      },
      {
        Header: "מספר טלפון",
        accessor: "phoneNumber",
      },
      {
        Header: "שם מלא",
        accessor: "fullName",
      },
      {
        Header: "תאריך יצירה",
        accessor: "creationDate",
        Cell: ({ value }) => {
          if (value == null) return "";
          return <span dir="ltr">{formatDate(value)}</span>;
        },
      },

      {
        Header: "שם עיר",
        accessor: "cityName",
      },
      {
        Header: "תפקיד",
        accessor: "roleName",
      },
      {
        Header: "פעיל",
        accessor: "isActive",
        Cell: ({ value }) => (value ? "פעיל" : "לא פעיל"),
      },
      {
        Header: "החלפת סיסמא",
        accessor: "changePassword",
        Cell: ({ row }) => (
          <button
            type="button"
            className="change-password-btn"
            onClick={() => handleChangePasswordClick(row.original.id)}
          />
        ),
      },
      {
        Header: "עריכה",
        accessor: "editId",
        Cell: ({ row }) => (
          <button type="button" className="edit-btn" onClick={() => handleEditClick(row.original.id)} />
        ),
      },
    ],
    []
  );

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <>
      {isAddModalOpen && (
        <UserFormComponent
          onClose={handleCloseModal}
          onSave={handleSave}
          citiesRef={allCitiesNameRef}
          rolesRef={allRolesNameRef}
        />
      )}
      {isEditModalOpen && (
        <UserFormComponent
          onClose={handleCloseModal}
          onSave={handleSave}
          userId={editingUserId}
          citiesRef={allCitiesNameRef}
          rolesRef={allRolesNameRef}
        />
      )}
      {IsChangePasswordModalOpen && (
        <UserChangePasswordComponent onClose={handleCloseModal} onSave={handleSave} userId={editingUserId} />
      )}

      <div className="admin-manage-page">
        <h1>{TITLE}</h1>
        <div className="admin-manage-page-top">
          <div className="search-table-container">
            <div className="search-table-textbox">
              {allUserNamesRef.current && (
                <AutocompleteTextBox
                  className="basic-single"
                  isRtl={true}
                  // options={allUserNamesRef.current}
                  options={allUserNamesRef.current}
                  onChange={handleSerachTextChange}
                  placeholder="חפש משתמש.."
                  name="searchtext"
                />
              )}
            </div>
            {auth?.roles?.includes("SuperAdmin") && (
              <>
                <div className="search-table-select">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleCityChange}
                    defaultValue={0}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={true}
                    isSearchable={true}
                    maxMenuHeight={300}
                    name="city"
                    options={allCitiesNameRef.current}
                    placeholder="בחר עיר"
                    noOptionsMessage={() => "לא נמצאו תוצאות"}
                  />
                </div>

                <div className="search-table-select">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleRoleChange}
                    defaultValue={0}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={true}
                    isSearchable={false}
                    maxMenuHeight={300}
                    name="role"
                    options={allRolesNameRef.current}
                    placeholder="בחר תפקיד"
                    noOptionsMessage={() => "לא נמצאו תוצאות"}
                  />
                  {/* <Select options={options} value={selectedRole} onChange={handleRoleChange} /> */}
                </div>
              </>
            )}

            <div className="search-table-button">
              <button type="button" onClick={handleSearch}>
                חפש
              </button>
            </div>
          </div>
          <div className="add-button">
            <button type="button" onClick={handleAddClick}>
              צור חדש
            </button>
          </div>
        </div>
        {data && data.length > 0 ? (
          <DataTable
            columns={columns}
            data={data}
            onPageChange={handlePageChange}
            pageIndex={pageIndex}
            isLoading={isLoading}
            pageCount={pageCount}
            pageSize={PAGE_SIZE}
          />
        ) : (
          <div>לא נמצאו נתונים להצגה</div>
        )}
      </div>
    </>
  );
};

export default ManageUsers;
