import React, { useState, useEffect } from "react";

const NavigationOpenNotifications = () => {
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    function handleStorageEvent(event) {
      if (event.key === "openNotifications") {
        const storedCount = localStorage.getItem("openNotifications");
        if (storedCount) {
          setNotificationCount(parseInt(storedCount));
        }
      }
    }
    // Listen for storage events
    window.addEventListener("storage", handleStorageEvent);
    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("storage", handleStorageEvent);
  }, []);

  return (
    <div className="notification-header-container">
      <div href="#" className="notification-header">
        <i></i>
        {notificationCount > 0 && <span className="badge">{notificationCount}</span>}
      </div>
    </div>
  );
};

export default NavigationOpenNotifications;
