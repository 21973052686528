import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import LoadingAnimation from "../Common/LoadingAnimation";
import AutocompleteTextBox from "../Common/AutocompleteTextBox";
import UnitFormComponent from "./UnitFormComponent";
import DataTable from "../Common/DataTable";
import Select from "react-select";

const PAGE_SIZE = 25;
const TITLE = "עריכת ערים";

const ManageUnits = () => {
  const { cityId } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const [city, setCity] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0); // the current page inporant for thae dataTable component to get changing in paging
  const [pageCount, setPageCount] = useState(0); // total pages for paging
  const [searchText, setSearchText] = useState("");
  const allCitiesNameRef = useRef("");
  const allUnitsNameRef = useRef("");
  const currentCityIdRef = useRef(0);

  // popup variables
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingUnitId, setEditingUnitId] = useState(null);
  // The data set after clicking the search button prevents API usage of the selections before the search click.
  const searchTextRef = useRef("");
  const isSuperAdmin = auth?.roles?.includes("SuperAdmin");

  // use effect for first reload of the page and cityId changed otherwise back browser button won't work properly
  useEffect(() => {
    setIsLoading(true);
    resetStates();

    const promises = [];
    // redirect admin if the city is not belong to, only SuperAdmin can see everything
    if (!isSuperAdmin && cityId != auth.cityId) {
      navigate("/", { state: { from: location }, replace: true });
    }
    if (cityId != null) {
      currentCityIdRef.current = Number(cityId);
      promises.push(getCityData(currentCityIdRef.current));
      promises.push(getUnitsNames(currentCityIdRef.current));
      promises.push(getDataList(0, currentCityIdRef.current));
    }
    // Area for SuperAdmin to show him select list of cities
    if (isSuperAdmin) {
      promises.push(getCitiesName());
    }

    Promise.all(promises).then(() => {
      setIsLoading(false);
    });
  }, [cityId]);

  // to get the city detail like name
  const getCityData = (id) => {
    axiosPrivate.get(`api/city/GetCityDetail/${id}`).then((response) => {
      setCity(response.data);
    });
  };

  // for super admin to select the city to show the currect table
  const getCitiesName = async () => {
    const response = await axiosPrivate.get("/api/City/GetNames");
    const citiesName = response.data.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    allCitiesNameRef.current = citiesName;
  };

  const getDataList = async (pageNumber, id) => {
    console.log("searchText = " + searchTextRef.current);
    console.log("id = " + id);
    // set the pageIndex - that is imporant for the DataTable component for currect paging
    setPageIndex(pageNumber);
    const response = await axiosPrivate.get(
      `api/Unit/GetPaged?cityId=${id}&pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}&searchText=${searchTextRef.current}`
    );
    setData(response.data.units);
    setPageCount(response.data.totalPages);
    console.log(response.data.units);
    console.log(response.data.totalPages);
  };

  const getUnitsNames = async (id) => {
    const response = await axiosPrivate.get(`/api/Unit/GetNames?cityId=${id}`);
    const unitNames = response.data.map((unit) => ({
      value: unit.id,
      label: unit.name,
    }));
    allUnitsNameRef.current = unitNames;
  };

  const resetStates = () => {
    // it's important to clear the text search otherwise the search filter remind when fetch data
    if (searchTextRef.current) searchTextRef.current = "";
    if (searchText) setSearchText("");
    if (data.length > 0) setData([]);
    if (currentCityIdRef.current) currentCityIdRef.current = "";
    if (allUnitsNameRef.current) allUnitsNameRef.current = "";
  };

  const handleCityChange = (selectedOption) => {
    // when doing that it change the value of the id in the url so it fetch the useEffect data
    navigate(`/admin/ManageUnits/${selectedOption.value}`);
  };

  // popup functions
  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleEditClick = (id) => {
    setEditingUnitId(id);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingUnitId(null);
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleSave = async () => {
    handleCloseModal();
    getUnitsNames(currentCityIdRef.current)
    getDataList(pageIndex, currentCityIdRef.current);
  };

  const handlePageChange = (value) => {
    // make sure pageIndex is not negative
    if (value < 0) value = 0;
    getDataList(value, currentCityIdRef.current);
  };

  const handleSerachTextChange = (value) => {
    setSearchText(value);
    console.log(value);
  };

  const handleSearch = () => {
    searchTextRef.current = searchText;
    getDataList(0, currentCityIdRef.current);
  };

  const formatDate = (value) => {
    if (!value) return ""; // return empty string if value is null or undefined
    const date = new Date(value);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "שם יחידה",
        accessor: "name",
      },
      {
        Header: "מספר יחידה",
        accessor: "sourcePhone",
      },
      {
        Header: "תאריך יצירה",
        accessor: "creationDate",
        Cell: ({ value }) => {
          return <span dir="ltr">{formatDate(value)}</span>;
        },
      },
      {
        Header: "יוצר היחידה",
        accessor: "creationUserName",
      },
      {
        Header: "קוד עיר",
        accessor: "cityId",
      },
      {
        Header: "תאריך דווח אחרון מהיחידה",
        accessor: "lastReportedDate",
        Cell: ({ value }) => {
          if (value == null) return "";
          return <span dir="ltr">{formatDate(value)}</span>;
        },
      },
      {
        Header: "תאריך עדכון אחרון",
        accessor: "lastClosedDate",
        Cell: ({ value }) => {
          if (value == null) return "";
          return <span dir="ltr">{formatDate(value)}</span>;
        },
      },
      {
        Header: "מעדכן אחרון",
        accessor: "lastClosedUserName",
      },
      {
        Header: "עריכה",
        accessor: "editId",
        Cell: ({ row }) => (
          <button type="button" className="edit-btn" onClick={() => handleEditClick(row.original.id)} />
        ),
      },
    ],
    []
  );

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <>
      {isAddModalOpen && (
        <UnitFormComponent onClose={handleCloseModal} onSave={handleSave} cityId={currentCityIdRef.current} />
      )}
      {isEditModalOpen && (
        <UnitFormComponent
          onClose={handleCloseModal}
          onSave={handleSave}
          unitId={editingUnitId}
          cityId={currentCityIdRef.current}
        />
      )}
      <div className="admin-manage-page">
        <h1>ניהול יחידות{city ? ` ב${city.name}` : ""}</h1>
        <div className="admin-manage-page-top">
          <div className="search-table-container">
            {isSuperAdmin && (
              <div className="admin-manage-page-change-city">
                <span className="label">בחר עיר:</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={handleCityChange}
                  defaultValue={allCitiesNameRef.current.find((option) => option.value === currentCityIdRef.current)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={true}
                  isSearchable={true}
                  maxMenuHeight={300}
                  name="city"
                  options={allCitiesNameRef.current}
                  placeholder="בחר עיר"
                  noOptionsMessage={() => "לא נמצאו תוצאות"}
                />
              </div>
            )}
            {/* to show search we need to make sure there is an city to show and the units alread loaded */}
            {currentCityIdRef.current && allUnitsNameRef.current ? (
              <>
                <div className="search-table-select">
                  <AutocompleteTextBox
                    className="basic-single"
                    isRtl={true}
                    // options={allUserNamesRef.current}
                    options={allUnitsNameRef.current}
                    onChange={handleSerachTextChange}
                    placeholder="חפש יחידה.."
                    name="searchtext"
                  />
                </div>
                <div className="search-table-button">
                  <button type="button" onClick={handleSearch}>
                    חפש
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {currentCityIdRef.current ? (
            <div className="add-button">
              <button type="button" onClick={handleAddClick}>
                צור חדש
              </button>
            </div>
          ): <></>}
        </div>
        {/* {cityId && <div>CityId: {cityId}</div>}
        {searchText && <div>searchText: {cityId}</div>} */}
        {data && data.length > 0 ? (
          <DataTable
            columns={columns}
            data={data}
            onPageChange={handlePageChange}
            pageIndex={pageIndex}
            isLoading={isLoading}
            pageCount={pageCount}
            pageSize={PAGE_SIZE}
          />
        ) : currentCityIdRef.current ? (
          <div>לא נמצאו נתונים להצגה</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ManageUnits;
