import { ThreeDots } from "react-loader-spinner";
import React from "react";

const LoadingAnimation = () => {
  return (
    <div className="loading-animation-container">
      <ThreeDots
        height="20"
        width="80"
        radius="9"
        color="#114abe"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export default LoadingAnimation;
