import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import LoadingAnimation from "../Common/LoadingAnimation";

const UserChangePasswordComponent = ({ onClose, onSave, userId }) => {
  const axiosPrivate = useAxiosPrivate();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(null);
  const [titleName, setTitleName] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const getUserDetails = async () => {
    const response = await axiosPrivate.get(`api/user/GetById/${userId}`);
    setTitleName(response.data.userName);
  };

  useEffect(() => {
    if (userId) {
      Promise.all([getUserDetails()]).then(() => setIsLoading(false));
    } else {
      onClose();
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPassword(value);
  };

  const handleToggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    let newErrors = {};

    if (/[\u0590-\u05FF]/.test(password)) newErrors.password = "הסיסמא לא יכולה להכיל תווים בעברית";
    if (!password) newErrors.password = "הזן סיסמא";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    axiosPrivate
      .post(`api/user/ChangePassword?UserId=${userId}&Password=${password}`)
      .then(() => {
        onSave();
        onClose();
      })
      .catch((error) => {
        setServerError("התרחשה שגיאה בביצוע הפעולה");
      });
  };

  return ReactDOM.createPortal(
    <div className="modal-background">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="modal-content edit-user-form">
          <form onSubmit={handleSubmit} autocomplete="off">
            <h2>{`החלפת סיסמא ל: ${titleName}`}</h2>
            <div className="form-group form-group-password">
              <label htmlFor="userName">
                <span className="required">*</span> הזן סיסמא חדשה:
              </label>
              <div className="app-text-box">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  dir="ltr"
                  name="password"
                  autocomplete="change-password"
                  value={password}
                  className={errors.password ? "error" : ""}
                  onChange={handlePasswordChange}
                />
                <div
                  className={`toggle-password ${showPassword ? "toggle-password-active" : ""}`}
                  onClick={handleToggleShowPassword}
                />
              </div>
              {errors.password && <p className="error-message">{errors.password}</p>}
            </div>

            {serverError && <p className="error-message">{serverError}</p>}
            <div className="form-actions">
              <button type="submit">שמור</button>
              <button className="cancel" type="button" onClick={onClose}>
                ביטול
              </button>
            </div>
          </form>
        </div>
      )}
    </div>,
    document.getElementById("portal")
  );
};

export default UserChangePasswordComponent;
