import React from "react";
import HomeMap from "../HomeMap/HomeMap";
const Home = () => {


  return (
    <section id="main-section">
      <HomeMap />
    </section>

  );
};

export default Home;
