import {useLocation, Navigate, Outlet} from "react-router-dom";
import useAuth from "../../Hooks/useAuth";


const RequireAuth = ({allowedRoles}) =>
{
    const {auth} = useAuth();
    const location = useLocation();
    
    //console.log(auth?.roles);
    console.log("RequireAuthuser auth:");
    console.log(auth?.userName);
    return (
        // if the role is correct show the route, otherwise direct to other page
        auth?.roles?.find(role => allowedRoles?.includes(role))
        ? <Outlet />
        // If the role is incorrect and the user exists, redirect to the unauthorized. Otherwise, redirect to the login.
        : auth?.userName
        ? <Navigate to="/unautorized" state={{from: location}} replace />
        : <Navigate to="/login" state={{from: location}} replace />
    );
}

export default RequireAuth;