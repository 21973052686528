import { useEffect, useRef } from "react";
import * as signalR from "@microsoft/signalr";
import useAuth from "./useAuth";
import { BASE_URL } from "../constants";

const useNotificationConnection = () => {
  const connectionRef = useRef(null);
  const cityIdRef = useRef(null);
  const onNewNotificationRef = useRef(null);
  const isMountedRef = useRef(true);
  const { auth } = useAuth();

  const startConnection = async () => {
    connectionRef.current = new signalR.HubConnectionBuilder().withUrl(BASE_URL + "/NotificationHub").build();

    connectionRef.current.on("ReceiveNotification", (notification) => {
      if (onNewNotificationRef.current) {
        onNewNotificationRef.current(notification);
      }
    });

    // if connection closed xx seconds
    connectionRef.current.onclose(() => {
      if (isMountedRef.current) {
        ReconnectOnError("sending reconnect in onclose");
      }
    });

    try {
      await connectionRef.current.start();
      // interval to reconnect the connection every xx minutes
      handleTimeRenewConnect();
    } catch (err) {
      ReconnectOnError("sending reconnect in catch error");
    }
  };

  useEffect(() => {
    console.log("starting the connect of signalR, Mounted status is: " + isMountedRef.current);
    startConnection();
    return () => {
      console.log("hook unmounted stoping the connection");
      isMountedRef.current = false;
      connectionRef.current.stop();
    };
  }, []);

  // 10 seconds to reconnect when error
  const ReconnectOnError = (status) => {
    console.log("ReconnectOnError excuted bcause of " + status);
    console.log("ReconnectOnError, server status: " + connectionRef.current.state);
    connectionRef.current.stop().then(() => {
      console.log("SignalR reconnecting...");
      startConnection();
      joinCurrentCity();
    });
  };

  // 55 minutes to renew connect to stay alive
  const handleTimeRenewConnect = () => {
    setTimeout(() => {
      connectionRef.current.stop().then(() => {
        console.log("handleTimeRenewConnect, server status: " + connectionRef.current.state);
        console.log("SignalR reconnecting...");
        startConnection();
        joinCurrentCity();
      });
    }, 55 * 60 * 1000);
  };

  const subscribeToNotifications = async (cityId, onNewNotification) => {
    cityIdRef.current = cityId;
    onNewNotificationRef.current = onNewNotification;
    console.log("before subscribeToNotifications, cityId: " + cityId);
    await joinCurrentCity();
  };

  const joinCurrentCity = async () => {
    if (cityIdRef.current != null) {
      // wait 0.5 seconds for the connection to be est
      let isConnected = connectionRef.current.state === "Connected";
      while (!isConnected) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        isConnected = connectionRef.current.state === "Connected";
      }

      console.log("JoinCurrentCity, server status: " + connectionRef.current.state);
      var userId  = auth.userId;
      var hubToken = auth.hubToken;
      var cityId = cityIdRef.current;
      await connectionRef.current.invoke("JoinCityGroup", cityId, userId, hubToken);
    } else {
      console.log("can't join group before cityRef is null");
    }
  };

  const changeCurrentCity = async (newCityId) => {
    await connectionRef.current.invoke("LeaveCityGroup", cityIdRef.current.toString());
    cityIdRef.current = newCityId;
    await joinCurrentCity();
  };

  return { subscribeToNotifications, changeCurrentCity };
};

export default useNotificationConnection;
