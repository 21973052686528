import React, { useMemo, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import Pagination from "./Pagination";

const DataTable = ({ columns, data, pageCount, pageIndex, pageSize, onPageChange }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
      pageCount,
      manualPagination: true,
    },
    usePagination
  );
  const handlePageChange = (value) => {
    console.log("1. handlePageChange on DataTable, pageIndex = " + value);
    onPageChange(value);
    console.log("2.handlePageChange on DataTable, pageIndex = " + value);
  };

  useEffect(() => {
    console.log("useEffect, pageIndex = " + pageIndex);
    gotoPage(pageIndex);
  }, [pageIndex]);

  const memoizedColumns = useMemo(() => columns, [columns]);

  return (
    <div className="app-table">
      {/* <div>canNextPage: {canNextPage ? "yes" : "no"}</div>
      <div>canPreviousPage: {canPreviousPage ? "yes" : "no"}</div> */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageCount={pageCount}
        handlePageChange={handlePageChange}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
      />
    </div>
  );
};

export default DataTable;
