import { Link, NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import useLogout from "../../Hooks/useLogout";
import NavigationOpenNotifications from "./NavigationOpenNotifications";

function Navigation() {
  const logout = useLogout();
  const { auth } = useAuth();
  const navigate = useNavigate();
  console.log("auth");
  console.log(auth);

  const signOut = async () => {
    //console.log("signOut - Start");
    await logout();
    //console.log("signOut - Before Navigate");
    navigate("/login");
  };

  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/">עמוד הבית</NavLink>
        </li>
        {auth?.roles?.includes("User") && (
          <li>
            <NavLink to="/contact">צור קשר</NavLink>
          </li>
        )}
        {auth?.roles?.includes("SuperAdmin") && (
          <>
            <li>
              <NavLink to="/admin/ManageUsers">ניהול משתמשים</NavLink>
            </li>
            <li>
              <NavLink to="/admin/ManageCities">ניהול ערים</NavLink>
            </li>
            <li>
              <NavLink to="/admin/ManageUnits">ניהול יחידות</NavLink>
            </li>
            <li>
              <NavLink to="/admin/Reports">דוחות</NavLink>
            </li>
            <li>
              <NavLink to="/contact">צור קשר</NavLink>
            </li>
          </>
        )}
        {auth?.roles?.includes("Admin") && (
          <>
            <li>
              <NavLink to={`/admin/ManageUnits/${auth.cityId}`}>ניהול יחידות</NavLink>
            </li>
            <li>
              <NavLink to="/admin/ManageUsers">ניהול משתמשים</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/Reports/${auth.cityId}`}>דוחות</NavLink>
            </li>
            <li>
              <NavLink to="/contact">צור קשר</NavLink>
            </li>
          </>
        )}
      </ul>
      {
        auth?.firstName ? (
          <div className="hello_user">
            <NavigationOpenNotifications />
            <span>שלום {auth?.firstName}</span>{" "}
            <button className="logout" onClick={signOut}>
              התנתק
            </button>
          </div>
        ) : null // When user is not login
      }
    </nav>
  );
}

export default Navigation;
