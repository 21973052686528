import { useRef, useState, useEffect } from "react";
import "./Login.css";
import useAuth from "../../Hooks/useAuth";
import axios from "../../Api/Axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

const LOGIN_URL = "/api/auth/token";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/";

  const userNameRef = useRef();
  const errorRef = useRef();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(() => {
    userNameRef.current.focus();
  }, []);

  useEffect(() => {
    seterrorMsg("");
  }, [userName, password]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ userName, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //console.log(JSON.stringify(response));
      const accessToken = response?.data?.accessToken;
      // use the input userName satate instead of response?.data?.userName
      // const userName = response?.data?.userName;
      const userId = response?.data?.userId;
      const roles = response?.data?.roles;
      const firstName = response?.data?.firstName;
      const lastName = response?.data?.lastName;
      const cityId = response?.data?.cityId;
      const hubToken = response?.data?.hubToken;
      setAuth({ userName,userId, firstName, lastName, roles, accessToken, cityId, hubToken });
      // redirect agter success login
      navigate(from, { replace: true });
    } catch (error) {
      if (!error?.response) {
        seterrorMsg("שגיאת חיבור לשרת");
      } else if (error?.response?.data && error.response.data.length > 0) {
        seterrorMsg(error.response.data[0]);
      } else {
        seterrorMsg("התרחשה שגיאה בתהליך ההתחברות, אנא נסה מאוחר יותר");
      }
      errorRef.current.focus();
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="username">שם משתמש:</label>
          <input
            type="text"
            id="username"
            className="form-control"
            ref={userNameRef}
            autoComplete="off"
            onChange={(event) => setUserName(event.target.value)}
            value={userName}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">סיסמא:</label>
          <input
            type="password"
            id="password"
            className="form-control"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            required
          />
        </div>
        <p ref={errorRef} className={errorMsg ? "error-message" : "offscreen"}>
          {errorMsg}
        </p>
        <button className="btn btn-primary">כניסה לפורטל</button>
      </form>
    </section>
  );
};

export default Login;
