import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const AutocompleteTextBox = ({ options, className, onChange, placeholder, name, isRtl }) => {
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const suggestionListRef = useRef(null);
  const componentRef = useRef(null);
  const inputRef = useRef(null);

  const handleTextChange = (event) => {
    const searchText = event.target.value;
    setText(searchText);
    // to ensure suggestion list is hidden when input field is empty by checking searchText.
    if (searchText) {
      const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

      setSuggestions(filteredOptions);
      setSelectedIndex(-1);
    }
    if (onChange) {
      onChange(searchText);
    }
  };

  const handleInputClick = (event) => {
    if (!suggestions.length > 0) handleTextChange(event);
  };

  const handleItemClick = (selectedItem) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1);

    setText(selectedItem.label);
    setSuggestions([]);
    setSelectedIndex(-1);

    if (onChange) {
      onChange(selectedItem.label);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (selectedIndex !== -1) {
        handleItemClick(suggestions[selectedIndex]);
      } else {
        handleItemClick({ label: text });
      }
    } else if (event.keyCode === 38) {
      // Up arrow
      setSelectedIndex((prevIndex) => (prevIndex <= 0 ? suggestions.length - 1 : prevIndex - 1));
    } else if (event.keyCode === 40) {
      // Down arrow
      setSelectedIndex((prevIndex) => (prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1));
    }
  };

  useEffect(() => {
    setSelectedIndex(-1);

    if (suggestionListRef.current && suggestions.length > 0) {
      suggestionListRef.current.firstChild.focus();
    }

    const handleClickOutside = (event) => {
      if (componentRef.current && suggestionListRef.current && !componentRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestions]);

  return (
    <div className={`${className} autocomplete-text-box`} dir={isRtl ? "rtl" : undefined} ref={componentRef}>
      <input
        type="text"
        value={text}
        placeholder={placeholder}
        name={name}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        onClick={handleInputClick}
        ref={inputRef}
      />
      {suggestions.length > 0 && (
        <ul className="autocomplete-text-box-list" ref={suggestionListRef}>
          {suggestions.map((suggestion, index) => (
            <li
              key={suggestion.label}
              className={index === selectedIndex ? "item-focus" : undefined}
              onMouseEnter={() => setSelectedIndex(index)}
              // onMouseLeave={() => setSelectedIndex(-1)}
              onClick={() => handleItemClick(suggestion)}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

AutocompleteTextBox.defaultProps = {
  className: "",
  placeholder: "",
  name: "",
  isRtl: false,
};

AutocompleteTextBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  isRtl: PropTypes.bool,
};

export default AutocompleteTextBox;
