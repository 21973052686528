import React, { useRef, useState } from "react";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import "./Contact.css";
const CONTACT_ADD_URL = "/api/supportContact/add";

const Contact = () => {
  const axiosPrivate = useAxiosPrivate();
  const titleRef = useRef();
  const reasonRef = useRef();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    const title = titleRef.current.value;
    const reason = reasonRef.current.value;

    let newErrors = {};

    if (!title) newErrors.title = "הזן את נושא הפניה";
    if (!reason) newErrors.reason = "הזן את סיבת הפניה";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const contactData = { Title: title, Reason: reason };

    try {
      const response = await axiosPrivate.post(CONTACT_ADD_URL, contactData);

      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setServerError("התרחשה שגיאה בתהליך השליחה, אנא נסה מאוחר יותר");
    }
  };

  return (
    <div className="contact-container contact-page">
      <h1>ברוכים הבאים</h1>
      <div className="header-info">כאן תכלו בקלות ליצור קשר לעזרה בכל הנושאים הקשורים למערכת</div>
      <div className="contact-form">
        <div className="right-side">
          {success ? (
            <p className="success-message">פנייתך התקבלה בהצלחה</p>
          ) : (
            <>
              <h2>איך אפשר לעזור לך?</h2>
              <div className="subheader-info">השאר פרטים ונחזור אליך בהקדם</div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    <span className="required">*</span> נושא הפניה:
                  </label>
                  <input ref={titleRef} className={errors.title ? "error" : ""} />
                  {errors.title && <p className="error-message">{errors.title}</p>}
                </div>
                <div className="form-group">
                  <label>
                    <span className="required">*</span> סיבת הפניה:
                  </label>
                  <textarea ref={reasonRef} className={errors.reason ? "error" : ""} style={{ height: "80px" }} />
                  {errors.reason && <p className="error-message">{errors.reason}</p>}
                </div>
                {serverError && <p className="error-message">{serverError}</p>}
                <button type="submit">שליחה</button>
              </form>
            </>
          )}
        </div>
        <div className="left-side">
          <img src={process.env.PUBLIC_URL + "/support_pic.png"} alt="Support" />
        </div>
      </div>
    </div>
  );
};
export default Contact;
