import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import Select from "react-select";
import LoadingAnimation from "../Common/LoadingAnimation";
import DatePicker from "react-datepicker";
import DataTable from "../Common/DataTable";
import * as XLSX from "xlsx/xlsx.mjs";

import "react-datepicker/dist/react-datepicker.css";

const PAGE_SIZE = 25;
const TITLE = "דוחות";

export default function ReportsComponent() {
  const { cityId } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [units, setUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0); // the current page inporant for thae dataTable component to get changing in paging
  const [pageCount, setPageCount] = useState(0); // total pages for paging
  const allCitiesNameRef = useRef("");

  // The currently selected search options states to save it before clicking on search to prevent API call use before that.
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // The data set after clicking the search button prevents API usage of the selections before the search click.
  const selectedCityRef = useRef(0);
  const selectedUnitRef = useRef(0);
  const selectedStatusRef = useRef(0);
  const startDateRef = useRef(0);
  const endDateRef = useRef(0);

  const isSuperAdmin = auth?.roles?.includes("SuperAdmin");

  // use effect for first reload of the page and cityId changed otherwise back browser button won't work properly
  useEffect(() => {
    setIsLoading(true);
    resetStates();

    const promises = [];

    // redirect admin if the city is not belong to, only SuperAdmin can see everything
    if (!isSuperAdmin && cityId != auth.cityId) {
      navigate("/", { state: { from: location }, replace: true });
    }

    selectedCityRef.current = Number(cityId);
    // set the city to SelectedCity state otherwise on search click it won't use the selected city
    setSelectedCity(selectedCityRef.current);

    promises.push(getUnitsNames(selectedCityRef.current));
    promises.push(getDataList(0));
    // Area for SuperAdmin to show him select list of cities
    if (isSuperAdmin) {
      promises.push(getCitiesName());
    }

    Promise.all(promises).then(() => {
      setIsLoading(false);
    });
  }, []);

  const getUnitsNames = async (id) => {
    const response = await axiosPrivate.get(`/api/Unit/GetNames?cityId=${id}`);
    const unitNames = response.data.map((unit) => ({
      value: unit.id,
      label: unit.name,
      sourcePhone: unit.sourcePhone,
    }));
    console.log("unitNames");
    console.log(response.data);

    setUnits(unitNames);
  };

  const getDataList = async (pageNumber) => {
    // set the pageIndex - that is imporant for the DataTable component for currect paging
    setPageIndex(pageNumber);

    var requrestUrl = `api/Notifications/ReportsGetPaged?
    &pageSize=${PAGE_SIZE}
    &cityId=${selectedCityRef.current}
    &pageNumber=${pageNumber}
    &unitId=${selectedUnitRef.current}
    &status=${selectedStatusRef.current}
    &startDate=${startDateRef.current}
    &endDate=${endDateRef.current}`;
    //alert(requrestUrl);
    const response = await axiosPrivate.get(requrestUrl);

    setData(response.data.notifications);
    setPageCount(response.data.totalPages);
    console.log(response.data);
    console.log(response.data.notifications);
    console.log(response.data.totalPages);
  };

  // for super admin to select the city to show the currect table
  const getCitiesName = async () => {
    const response = await axiosPrivate.get("/api/City/GetNames");
    const citiesName = response.data.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    allCitiesNameRef.current = citiesName;
  };

  const handlePageChange = (value) => {
    // make sure pageIndex is not negative
    if (value < 0) value = 0;
    getDataList(value);
  };

  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      getUnitsNames(selectedOption.value);
      setSelectedCity(selectedOption.value);
    } else {
      getUnitsNames(0);
      setSelectedCity(0);
    }
  };

  const handleUnitChange = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption) setSelectedUnit(selectedOption.value);
    else setSelectedUnit(0);
  };

  const handleStatusChange = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption) setSelectedStatus(selectedOption.value);
    else setSelectedStatus(0);
  };

  const resetStates = () => {
    // it's important to clear the text search otherwise the search filter remind when fetch data
  };

  const handleStartDateChange = (date) => {
    if (date.currentTarget.value) {
      const [day, month, year] = date.currentTarget.value.split("/");
      const newRaw = new Date(year, month - 1, day);
      if (newRaw instanceof Date && !isNaN(newRaw)) {
        setStartDate(newRaw);
      }
    }
  };
  const handleEndDateChange = (date) => {
    if (date.currentTarget.value) {
      const [day, month, year] = date.currentTarget.value.split("/");
      const newRaw = new Date(year, month - 1, day);
      if (newRaw instanceof Date && !isNaN(newRaw)) {
        setEndDate(newRaw);
      }
    }
  };

  const handleSearch = () => {
    if (auth?.roles?.includes("SuperAdmin")) {
      selectedCityRef.current = selectedCity;
    } else {
      selectedCityRef.current = cityId;
    }
    selectedUnitRef.current = selectedUnit;
    // show all if the user didn't select status
    selectedStatusRef.current = selectedStatus ? selectedStatus : 0;
    startDateRef.current = "";
    endDateRef.current = "";
    // set the start date to the first moment of the day
    if (startDate) {
      const startDateTime = new Date(startDate);
      startDateTime.setHours(0, 0, 0, 0);
      startDateRef.current = startDateTime.toISOString();
    }
    if (endDate) {
      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59, 999);
      endDateRef.current = endDateTime.toISOString();
    }

    getDataList(0);
  };

  const handleExportExcell = async () => {
    var requrestUrl = `api/Notifications/ReportsToExcell?
  &cityId=${selectedCityRef.current}
  &unitId=${selectedUnitRef.current}
  &status=${selectedStatusRef.current}
  &startDate=${startDateRef.current}
  &endDate=${endDateRef.current}`;
    //alert(requrestUrl);

    const response = await axiosPrivate.get(requrestUrl);

    const headings = ["מזהה", "שם יחידה", "טלפון יחידה", "שם עיר", "תאריך יצירה", "תאריך סגירה", "משתמש מטפל"];
    const dateTimeOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const data = response.data.map((notification) => {
      return {
        Id: notification.id,
        UnitName: notification.unitName,
        UnitPhone: notification.unitPhone,
        CityName: notification.cityName,
        CreactionDate: new Intl.DateTimeFormat("he-IL", dateTimeOptions).format(new Date(notification.creactionDate)),
        ClosedDate: new Intl.DateTimeFormat("he-IL", dateTimeOptions).format(new Date(notification.closedDate)),
        ClosedUserName: notification.closedUserName,
      };
    });

    var workbook = XLSX.utils.book_new();

    var worksheet = XLSX.utils.json_to_sheet(data, { origin: "A2", skipHeader: true });
    worksheet.SheetViews = [
      {
        rightToLeft: true,
      },
    ];
    XLSX.utils.sheet_add_aoa(worksheet, [headings]); //heading: array of arrays


    XLSX.utils.book_append_sheet(workbook, worksheet);

    const now = new Date();
    const filename = `report_${now.getDate().toString().padStart(2, "0")}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${now.getFullYear()}_${now.getHours().toString().padStart(2, "0")}-${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}-${now.getSeconds().toString().padStart(2, "0")}.xlsx`;

    XLSX.writeFile(workbook, filename);
  };

  const days = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
  const months = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
  const StatusOptions = [
    { value: 0, label: "כל ההתראות" },
    { value: 1, label: "התראות פתוחות" },
    { value: 2, label: "התראות סגורות" },
  ];

  const formatDate = (value) => {
    if (!value) return ""; // return empty string if value is null or undefined
    const date = new Date(value);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "מספר התראה",
        accessor: "id",
      },
      {
        Header: "שם יחידה",
        accessor: "unitName",
      },
      {
        Header: "מספר יחידה",
        accessor: "unitPhone",
      },
      {
        Header: "שם עיר",
        accessor: "cityName",
      },
      {
        Header: "תאריך פתיחה",
        accessor: "creactionDate",
        Cell: ({ value }) => {
          if (value == null) return "";
          return <span dir="ltr">{formatDate(value)}</span>;
        },
      },
      {
        Header: "תאריך סגירה",
        accessor: "closedDate",
        Cell: ({ value }) => {
          if (value == null) return "";
          return <span dir="ltr">{formatDate(value)}</span>;
        },
      },
      {
        Header: "משתמש מטפל",
        accessor: "closedUserName",
      },
    ],
    []
  );

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <>
      <div className="admin-manage-page">
        <h1>דוחות</h1>
        <div className="admin-manage-page-top">
          <div className="search-table-container">
            {auth?.roles?.includes("SuperAdmin") && (
              <>
                <div className="search-table-select">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleCityChange}
                    defaultValue={allCitiesNameRef.current.find((option) => option.value === selectedCityRef.current)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={true}
                    isSearchable={true}
                    maxMenuHeight={300}
                    name="city"
                    options={allCitiesNameRef.current}
                    placeholder="בחר עיר.."
                    noOptionsMessage={() => "לא נמצאו תוצאות"}
                  />
                </div>
              </>
            )}
            {units ? (
              <div className="search-table-select">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={handleUnitChange}
                  defaultValue={null}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={true}
                  isSearchable={true}
                  maxMenuHeight={300}
                  name="unit"
                  options={units}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.sourcePhone}
                  placeholder="בחר יחידה.."
                  noOptionsMessage={() => "לא נמצאו תוצאות"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="search-table-select app-text-box select-date">
              <DatePicker
                selected={startDate}
                required={false}
                locale={locale}
                dateFormat="dd/MM/yyyy"
                placeholderText="תאריך התחלה.."
                onChange={(date) => setStartDate(date)}
                isClearable={true}
                onChangeRaw={handleStartDateChange}
                showYearDropdown={true}
                showMonthDropdown={true}
                yearDropdownItemNumber={20}
                minDate={new Date(2023, 0, 1)}
                maxDate={endDate ? endDate : tomorrow}
              />
            </div>
            <div className="search-table-select app-text-box select-date">
              <DatePicker
                selected={endDate}
                required={false}
                locale={locale}
                dateFormat="dd/MM/yyyy"
                placeholderText="תאריך סיום.."
                onChange={(date) => setEndDate(date)}
                isClearable={true}
                onChangeRaw={handleEndDateChange}
                showYearDropdown={true}
                showMonthDropdown={true}
                yearDropdownItemNumber={20}
                minDate={startDate ? startDate : new Date(2023, 0, 1)}
                maxDate={tomorrow}
              />
            </div>
            <div className="search-table-select">
              <Select
                className="basic-single"
                classNamePrefix="select"
                onChange={handleStatusChange}
                defaultValue={null}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={true}
                maxMenuHeight={300}
                name="openStatus"
                options={StatusOptions}
                placeholder="בחר סטטוס התראה.."
                noOptionsMessage={() => "לא נמצאו תוצאות"}
              />
            </div>
            <div className="search-table-button">
              <button type="button" onClick={handleSearch}>
                חפש
              </button>
            </div>
          </div>
        </div>
        {data && data.length > 0 ? (
          <>
            <button className="export_to_excel" type="button" onClick={handleExportExcell}>
              יצא לאקסל
            </button>
            <DataTable
              columns={columns}
              data={data}
              onPageChange={handlePageChange}
              pageIndex={pageIndex}
              isLoading={isLoading}
              pageCount={pageCount}
              pageSize={PAGE_SIZE}
            />
          </>
        ) : (
          <div>לא נמצאו נתונים להצגה</div>
        )}
      </div>
    </>
  );
}
