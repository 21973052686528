import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import Axios from "../Api/Axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const {auth} = useAuth();
  const location = useLocation();

  const refresh = async () => {
    try {
      //console.log("start refresh in useRefreshToken");
      //console.log(auth);
      const response = await Axios.get("/api/auth/refresh-token", {
        withCredentials: true,
      });
      setAuth((prev) => {
        //console.log("useRefreshToken start setAuth");
        //console.log(JSON.stringify(prev));
        //console.log(response.data.accessToken);
        return {
          ...prev,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          cityId: response.data.cityId,
          roles: response.data.roles,
          accessToken: response.data.accessToken,
          userName: response.data.userName,
          userId: response.data.userId,
          hubToken: response.data.hubToken,
        };
      });
      return response.data.accessToken;
    } catch (error) {
      console.log("useRefreshToken catch in error");
      if (error?.response?.status === 401) {
        console.log("refresher sending to login page because cookies is not valid");
        // clear setAuth and redirect to login page
        setAuth({});
        <Navigate to="/login" state={{ from: location }} replace />;
      }
      console.log(error);
      console.log("error status code: " + error?.response?.status);
      console.log(auth);
      if (!error?.response) {
        console.log("server connection error");
      }
    }
  };
  return refresh;
};

export default useRefreshToken;
